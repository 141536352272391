/**
 * Mobile Slideout Navigation
 */

.slideout-menu-toggle {
  position: relative;
  display: none;
  float: right;
  text-align: center;
  padding: 14px 20px 0;
  transition: all 0.5s;
  margin-top: 27px;
  color: #fff;
  height: 47px;
  background-color: $blue;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 99;

  &:hover {
    background-color: $blue-dark;
    cursor: pointer;
  }
}

.slideout-menu-background {
  display: none;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 98;
}

.slideout-menu {
  display: none;
  position: fixed;
  top: 0;
  right: -270px;
  width: 270px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  border-left: 1px solid $gray-border;
  z-index: 100;
  overflow-y: auto;
  pointer-events: none;

  h3 {
    font-size: 1.75em;
    position: relative;
    padding: 20px 10px 26px;
    border-bottom: 1px solid $gray-border;
    color: $orange;
  }

  a.slideout-menu-toggle {
    float: right;
    text-align: center;
    padding: 0 16px;
    margin: -9px 10px 0 0;
    font-size: 1.5em;
    color: #fff;
    height: 52px;
    background-color: $blue;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    line-height: 49px;
    pointer-events: all;

    &:hover {
      background-color: $blue-dark;
      cursor: pointer;
    }
  }

  /* First Level */

  ul.menu {
    margin: 0;
    padding: 0 7px;
    pointer-events: all;

    &:first-child > li {
      background-image: none;
      width: 95%;
      margin-bottom: 2px;
      padding: 0;
      display: inline-block;
      *display: inline;
      zoom: 1;
      border-bottom: 1px solid #e0e2db;

      a {
        color: $blue;
        display: block;
        font-size: 1.250em; /* 20px */
        font-weight: bold;
        line-height: 1.25em;
        padding: 8px 3px;
        text-decoration: none;

        &:hover, &.active {
          color: $blue-dark;
          text-decoration: none;
        }

        &.active-trail {
          color: $orange;
          text-decoration: none;
        }
      }

      /* Second Level */

      &.expanded ul.menu {
        padding-bottom: 10px;

        li {
          background-image: none;
          margin-left: 0;
          padding-left: 0;
          list-style: none;

          a {
            color: $blue;
            font-weight: normal;
            font-size: 1em; /* 16px */
            text-decoration: none;
            padding: 5px 0px 0px 3px;
            line-height: 1em;

            &:hover, &:focus {
              color: $blue-dark;
              text-decoration: none;
            }

            &.active {
              color: $orange;
              text-decoration: none;
            }

            &::before {
              content: '\2022';
              padding-right: 7px;
              font-size: 0.9em;
            }
          }

          /* Third Level */

          &.expanded ul.menu {
            padding-bottom: 2px;

            li {
              background-image: none;
              margin-left: 0;
              padding-left: 13px;
              list-style: none;

              a {
                font-weight: normal;
                font-size: 1em; /* 16px */
                line-height: 1em;
                text-decoration: none;
                padding: 5px 0px 0px 3px;

                &:hover, &:focus {
                  color: $blue-dark;
                  text-decoration: none;
                }

                &.active {
                  color: $orange;
                  text-decoration: none;
                }

                &::before {
                  font-family: FontAwesome;
                  content: '\f105';
                  padding-right: 7px;
                }
              }
            }
          }
        }
      }
    }
  }
}

