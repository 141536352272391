/**
 * General
 */

html.js, html.no-js {
  height: 100%;
  min-width: 320px;
  background: $body-bg;
  overflow-x: auto;
}
body {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: auto;
  font-family: $font-family;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $text-color;
}
.clear-both {
  clear: both;
}
.clear-break {
  clear: both;
  font-size: 0;
  line-height: 0;
  height: 0;
}
textarea {
  resize: none;
}
p {
  margin: 0 0 1em 0;
  padding: 0;
}
a {
  color: $link-color;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;

  &:hover, &:focus {
    color: $link-hover-color;
    text-decoration: underline;
  }
}

h1 {
  font-family: $font-family-heading;
  font-size: $font-size-h1;
  color: $blue;
  font-weight: bold;
  line-height: 1.25em;
  padding-bottom: 0.6em;
  letter-spacing: 0.01em;
}

h2 {
  font-family: $font-family-heading;
  font-size: $font-size-h2;
  color: $blue;
  font-weight: bold;
  line-height: 1.2em;
  padding-bottom: 0.45em;
  letter-spacing: 0.01em;
}

h3 {
  font-family: $font-family-heading;
  font-size: $font-size-h3;
  color: $blue;
  line-height: 1.2em;
  padding-bottom: 0.45em;
  letter-spacing: 0.01em;
}

h4 {
  font-family: $font-family-heading;
  font-size: $font-size-h4;
  color: $blue;
  line-height: 1.45em;
  padding-bottom: 0.6em;
}

h5 {
  font-family: $font-family-heading;
  font-size: $font-size-h5;
  color: $blue;
  font-weight: bold;
  line-height: 1.45em;
  padding-bottom: 0.6em;
}

h6 {
  font-family: $font-family-heading;
  font-size: $font-size-h6;
  color: $blue;
  font-weight: bold;
  line-height: 1.45em;
  padding-bottom: 0.6em;
}

.marker, .form-required {
  color: #999;
  font-size: 0.8em;
}

.site-main-content #page-title {
  padding-top: 10px;
}

#cboxOverlay {
  background: none !important;
  background-color: $blue-dark !important;
}/* Color Box Override */

.content {

  fieldset {
    border: 1px solid #ccc;
    background: #fff;
    margin: 4px 2px 20px 2px;
    padding: 6px 14px 16px;

    &.checkout-buttons {
      padding: 6px 14px 6px;
    }

    legend {
      color: $blue;
      font-size: 1.125em;
      font-weight: bold;
    }
  }

  input.form-item, textarea.form-item {
    background: #fff;
    border: 1px solid #555;
    font-size: 1em;
    font-family: Verdana, Geneva, sans-serif;
  }

  input.form-text {
    height: 20px;
    line-height: 1.67em;
  }

  ul {
    margin: 0 0 1em 0;

    li {
      padding: 0;
      margin: 0 0 0.5em 0;
      line-height: $line-height-base;
      list-style: circle;

      a.active {
        color: $orange;
      }
    }
  }
  ol {
    margin: 0 0 1em -10px;

    li {
      padding: 0 0 0.5em 7px;
      margin: 0;
      line-height: $line-height-base;
    }
  }
}

img {
  max-width: 100%;
  width: auto;
  height: auto;
}

.form-submit,
.btn-element,
.action-links a,
#b-submit-review {
  display: inline-block;
  padding: 3px 20px;
  margin: 0;
  border: 0;
  background: $orange-light;
  text-decoration: none;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;

  &:hover, &:focus {
    background: $orange;
  }
}

ul.action-links {
  padding: 0;

  li {
    list-style: none;
  }
}
/**
 * Site Container
 */

div#site-wrapper {
  margin: 0;
  padding: 0;
  width: 100%;
  background: #fff;
}

.container {
  margin: 0 auto;
  padding: 0 10px;
  max-width: 1180px;
  text-align: left;
}

/**
 * Site Content
 */

/* -- Layout C -- */

#site-content {

  #col-main-left-c {
    float: left;
    width: 77.1%;
  }

  #col-left-c {
    float: left;
    width: 29.7%;

    .add-pad {
    }

    section {
      margin: 0;
      padding: 0 0 24px 0;
    }
  }

  #col-mid-c {
    float: right;
    width: 70.3%;

    .add-pad {
      padding: 0 20px 0 20px;
    }
  }

  #col-main-right-c {
    float: right;
    width: 22.9%;

    .add-pad {
    }
  }
}

/* -- Layout Span -- */

#site-content {

  #col-left-span {
    width: 100%;

    .add-pad {
    }
  }
}

/* -- All Site Content -- */

#site-content {
  clear: both;
  margin: 0 0 20px 0;
}
#site-wrapper.page-content #site-content {
  margin-top: 30px;
}
#site-content .article_header {
  color: #666;
  font-weight: normal;
}

.page_with_sec_nav #site-main-cont {
}

section#news_articles article.column {
  border-bottom: 1px solid #ccc;
  padding: 8px 0 14px 0;
  margin: 0 0 12px 0;
}

section#testimonials article.column {
  padding: 8px 0 14px 0;
  margin: 0 0 12px 0;
}

article.column h2, article.column h2 a {
  font-size: 1.17em;
  line-height: 1.14em;
  font-weight: bold;
  text-decoration: none;
}

article.column p.article_date, article.column p.article_author {
  font-style: italic;
  color: #999;
}

article.column p.article_quote {
  font-weight: bold;
}

article.page {
  border-bottom: 1px solid #c6c6c6;
  margin: 0 0 14px 0;
  padding: 0 0 4px 0;
}

#site-left-col {
}

#site-left-col-content {
  padding: 14px 0 0 0;
}

/**
 * Multilingual Styles
 */

#l10n-client.hidden {
  visibility: visible;
  display: block;
}

#product-details a.colorbox-node:hover {
  text-decoration: underline;
}

img.commerce-paypal-ec-icon {
  top: 0px;
}
