/**
 * Footer
 */

footer {
  min-height: 200px;
  background-color: #ededed;
  border-top: 1px solid $gray-border;
}

/**
 * Footer Top
 */

#ft-top {
  border-bottom: 1px solid $gray-border;
  padding: 24px 0 15px;

  .footer-logo {
    padding: 0;
    margin: 0;
    max-width: 200px;
    display: inline-block;
  }

  /* Social Menu */
  .footer-social {
    float: right;
    width: 50%;
    padding-top: 16px;

    ul.menu {
      padding: 0;
      margin: 0;
      text-align: right;

      li {
        display: inline-block;
        position: relative;
        padding: 0;
        margin: 0 4px 0 0;
        width: 36px;
        height: 36px;
        line-height: 36px;
        zoom: 1;
        background: $blue;
        cursor: pointer;
        text-align: center;
        @include border-radius(18px);
        -webkit-transition: all 0.25s;
        -moz-transition: all 0.25s;
        -ms-transition: all 0.25s;
        -o-transition: all 0.25s;
        transition: all 0.25s;

        &:hover, &:focus {
          background: $blue-dark;
        }

        a {
          display: block;
          position: absolute;
          top: 0;
          width: 36px;
          height: 36px;
          zoom: 1;
          text-indent: -9999px;
        }

        &::before {
          font-family: FontAwesome;
          color: #fff;
          font-size: 1.25em;
          pointer-events: none;
        }

        &.li-facebook::before {
          content: '\f09a';
        }

        &.li-twitter::before {
          content: '\f099';
        }

        &.li-youtube::before {
          content: '\f167';
        }

        &.li-google::before {
          content: '\f0d5';
        }
      }
    }
  }
}

/**
 * Footer Bottom
 */

#ft-bot {
  padding: 9px 0 0;

  .footer-links {
    float: right;
    text-align: right;
    margin: 1px 0 0 0;
    font-weight: bold;
    width: 60%;

    ul.menu {
      text-align: right;
      padding: 0;
      margin-bottom: 10px;

      li {
        padding: 0 0 5px;
        margin: 0;
        display: inline-block;
        *display: inline;
        zoom: 1;
        background: none;

        &:before {
          content: '|';
          padding: 0 19px 0 15px;
          color: #cccccc;
          font-weight: bold;
        }

        a {
          display: inline-block;
          *display: inline;
          zoom: 1;
          text-align: right;
          margin: 0;
          padding: 0;
          white-space: nowrap;
        }
      }

      li.active-trail a {
        color: $blue-dark;
      }

      li.first:before {
        content: '';
        padding: 0;
      }
    }
  }

  .ft-copy {
    font-size: 0.750em; /* 12px */
    color: $gray-light;
    padding: 3px 0 0 0;

    a {
      color: $blue-dark;

      &:hover {
        color: $blue;
      }
    }
  }

  .footer-acro-copy {
    clear: left;
    color: $gray-light;
    font-size: 0.625em; /* 10px */
    padding: 2px 0 0 0;

    a {
      color: $gray-light;

      &:hover {
        color: $gray-light;
        text-decoration: underline;
      }
    }
  }
}
