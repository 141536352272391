/**
 * Comments
 */

.blog_post ul.links {
  display: none;
}

#comment-form {

  .form-text, .form-textarea {
    background: #fff;
    color: #666;
    border: 1px solid #ccc;
    padding: 5px;
  }

  .form-item {

    input.error, textarea.error, .select.error {
      border: 2px solid red;
    }
  }

  span.form-required {
    display: none !important;
  }
}

#comments {
  clear: both;
  padding: 15px 0 20px 0;

  ul.links {
    display: inherit;
  }

  .filter-wrapper {
    display: none !important;
  }

  .grippie {
    display: none !important;
  }

  #comment-body-add-more-wrapper .filter-wrapper {
    display: none !important;
  }

  #comment-body-add-more-wrapper .grippie {
    display: none !important;
  }

  h2 {
    font-size: 1.33em;
    margin: 0 0 6px 0;
    padding: 0;
  }

  h3 {
    font-size: 1.25em;
    margin: 0 0 6px 0;
    padding: 0;
  }

  .links {
    margin: 0;
    padding: 0;

    li {
      display: inline;
      margin: 0 10px 0 0;
      padding: 0;
      background: none;
    }
  }
}

.comment-text .content {
  padding: 0 0 10px 0;
}

/* -- Actual Comment Entry -- */

.comment {
  padding: 10px;
  margin: 0 0 10px 0;
  background: #ededed;
  border-bottom: 1px solid $gray-border;
  @include border-radius(5px);

  .submitted {
    color: #686868;
    font-size: 0.92em;
  }

  span.new {
    display: none;
  }
}

.comment-author-info {
}

.commenter-name {
  font-weight: bold;
}

.comment-time {
  font-style: italic;
}

#comment-controls {
  clear: both;
  padding: 12px 0 0 0;
}

#comment-form #edit-submit {
  float: right;
}
