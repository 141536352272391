/**
 * FAQ
 */
.faq {
  margin: 0 0 7px 0;
  padding: 0;
  border-bottom: 1px solid $gray-border;

  .faq-question {
    display: block;
    position: relative;
    background: none;
    padding: 0 0 7px 20px;
    border: none;
    color: $blue;
    cursor: pointer;
    font-weight: bold;

    &::before {
      font-family: FontAwesome;
      color: $orange;
      content: "\f196";
      font-size: 1em;
      font-weight: normal;
      position: absolute;
      left: 0;
      top: 1px;
      pointer-events: none;
      padding-right: 5px;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
    }

    &.active::before {
      content: "\f147";
    }

    a {
      text-decoration: none;
      font-weight: bold;
    }

    &:hover {
      color: $blue-dark;

      &::before {
        font-size: 1.1em;
      }
    }

  }

  .faq-question-answer.expanded .faq-question {
    color: $blue-dark;
  }

  .faq-answer {
    display: none;
    border: none;
    padding: 15px 20px 1px;
    background: #ededed;
    background: -moz-linear-gradient(top, #fff 0%, #ededed 100%);
    background: -webkit-linear-gradient(top, #fff 0%, #ededed 100%);
    background: linear-gradient(to bottom, #fff 0%, #ededed 100%);
  }
}
