/**
 * Contact Us
 */

#panel-contact-us {
  padding-top: 10px;

  h2 {
    font-size: 1.5rem;
  }

  h6 {
    font-size: 0.9em;
    font-weight: normal;
    padding-bottom: 1.5em;
  }

  .panel-col-first {
    width: 48%;
    float: left;

    .inside {
      margin-right: 20px;
    }

    h2:nth-child(2) {
      display: none;
    }

    .map-link {
      display: none;
    }

    .pane-content .node-location {
      display: block;
    }
  }

  .panel-col-last {
    width: 48%;
    float: right;

    form#webform-client-form-7 {
      padding: 5px 20px 45px;
      background-color: #ededed;
      -webkit-border-radius: 5px;
      border-radius: 5px;

      .content input.form-text, .content textarea {
        display: inline-block;
      }

      div.webform-component {
        padding-bottom: 0;
      }

      input, textarea {
        width: 95%;
      }

      textarea {
        padding: 7px 9px;
      }

      input.webform-submit {
        width: auto;
      }
    }

    span {
      font-size: 0.9em; /* 15px */
    }
  }

  iframe {
    width: 100%;
  }
}

#contact-us-content input#edit-submit {
  margin: 0;
  padding: 0;
  text-decoration: none;
  border: 0;
  text-indent: -9999px;
  width: 144px;
  height: 29px;
  line-height: 29px;
  background: url(../gfx/b_submit.png) 0 0 no-repeat;
  display: block;

  &:hover {
    background-position: 0 -29px;
  }
}
