/**
 * Site Map
 */

#site-map {
  margin: 0;
  padding: 0;

  ul li, ul li.leaf, ul li.expanded {
    padding-left: 15px;
  }
}
