/**
 * Media Queries
 */

/* 850px and above */
@media screen and (min-width: 850px) {

  #site-left-col {
    float: left;
    width: 220px;
  }

  #site-main-cont {
    float: right;
    width: calc(100% - 260px);
    padding-bottom: 20px;
  }
  #site-main-cont.wide {
    float: none;
    width: 100%;
  }

  #sec-nav-cont ul.menu li {
    width: 220px;
  }
}

/* 1200px and below */
@media screen and (max-width: 1200px) {

  /* -- Homepage Search -- */
  #top-ctas h2 {
    font-size: 1.5em;
  }

  #footer-text {
    padding-left: 15px;
    padding-right: 15px;
  }

  .sec-nav-header {
    display: none;
  }

  #site-left-col .view-ctas {
    display: none;
  }

  /* Layout C Styles */
  #site-content #col-main-left-c {
    width: 100%;
  }

  #site-content #col-main-right-c {
    clear: both;
    width: 100%;
  }

  #layout #sub-ctas .views-row {
    margin: 0 1.9% 0 0;
  }

  #layout #sub-ctas .views-row-last {
    margin: 0;
  }

  #site-main-cont {
    padding-bottom: 20px;
  }
}

/* 1050px and below */
@media screen and (max-width: 1050px) {

  /* -- Homepage Search -- */
  #top-ctas .views-row a.top-cta-link {
    padding: 20px 10px 30px;

    .top-cta-text {
      padding-left: 0;

      h2 {
        font-size: 1.25em;
      }
    }
  }
}

/* 1000px and below */
@media screen and (max-width: 1000px) {

  /* -- Primary Nav -- */
  #pri-nav-cont ul.menu:first-child > li a {
    font-size: 1.125em;
    margin: 0 0 0 11px;
    padding: 3px 0 3px 14px;
  }

  #pri-nav-cont ul.menu:first-child > li.expanded > span > ul.menu li a {
    font-size: 1rem;
  }

  /* -- Homepage Featured CTA -- */
  #homepage-bottom-content #bottom-left .views-row a.featured-cta {
    .featured-cta-title h3 {
      font-size: 1.25rem;
    }

    .featured-cta-link {
      font-size: 1em;
    }
  }

  /* -- Product Specs -- */
  #product-tab-specs .specs-wrapper {
    .field-label {
      width: 49%;
    }

    .field-items {
      width: 49%;
    }
  }
}

/* 950px and below */
@media screen and (max-width: 1050px) {

  /* -- Commerce -- */
  .commerce-product-extra-field-title {
    display: block;
  }

  #product-details .commerce-product-extra-field-title {
    display: none;
  }

  #product-images {
    float: none;
    margin: 0 auto 30px;
  }

  #product-details {
    float: none;
    width: 100%;
    padding-bottom: 20px;
  }
}

/* 850px and below */
@media screen and (max-width: 850px) {
  /* -- Top Bar Right -- */
  #top-bar .top-bar-right {
    clear: right;
    padding-right: 7px;
    width: 100%;
    text-align: right;

    .login-link {
      padding: 0 20px 0 0;
      margin-right: 14px;
    }
  }

  /* -- Primary Nav -- */
  #pri-nav-cont {
    display: none;
  }

  .slideout-menu-toggle {
    display: inline-block;
  }

  .slideout-menu {
    display: block;
  }

  /* -- Mobile Filters -- */
  #mobile-product-filters-cont {
    display: block;
  }

  /* -- Left Column -- */
  #site-left-col {
    display: none;
  }
}

/* 820px and below */
@media screen and (max-width: 820px) {
    /* -- Homepage Bottom Content -- */
  #bottom-left, #bottom-right {
    float: none;
    width: 100%;
  }
}

/* 770px and below */
@media screen and (max-width: 770px) {
  #site-content #col-main-content {
    clear: both;
    width: 100%;
  }
  #site-content #col-sub-content {
    clear: both;
    width: 100%;
  }

  /* -- Homepage Search -- */
  #home-search .home-search-cont {
    h2 {
      font-size: 1.750em;
    }

    #views-exposed-form-product-catalog-page .views-widget-filter-search_api_views_fulltext .form-item-search-api-views-fulltext input.form-text {
      font-size: 1.25em;
    }
  }

  /* -- Homepage Top CTAs -- */
  #top-ctas .view-ctas .view-content {
    display: block;

    .views-row {
      margin: 0 auto 5px;
      min-width: 300px;
      display: block;
      max-width: 410px;

      a.top-cta-link .top-cta-text {
        padding-left: 10px;
      }
    }

    .views-row.views-row-2 {
      margin: 0 auto 5px;
    }
  }
}

/* 720px and below */
@media screen and (max-width: 720px) {

  /* Layout Styles */
  #site-content #col-main-left-c #col-mid-c {
    clear: both;
    width: 100%;
  }
  #site-content #col-main-left-c #col-mid-c .add-pad {
    padding: 0;
  }

  #site-content #col-main-left-c #col-left-c {
    clear: both;
    width: 100%;
  }

  #panel-contact-us .panel-col-first {
    clear: both;
    width: 100%;
    float: none;
    padding: 20px 0 20px 10px;

    .map-link {
      display: block;
    }

    .pane-content .node-location {
      display: none;
    }
  }

  #panel-contact-us .panel-col-last {
    clear: both;
    width: 100%;
    float: none;
  }
}

/* 700px and below */
@media screen and (max-width: 700px) {
  /* -- Currency Selector -- */
  #site-header {
    padding: 0 0 24px;
  }

  /* Footer */
  #ft-bot {
    .footer-links {
      width: 100%;

      ul.menu {
        text-align: center;
        margin-left: 0;
      }
    }

    .ft-copy, .footer-acro-copy {
      text-align: center;
    }
  }

  /* Related Products */
  #product-page-related-products .product-category {
    width: 47%;
    margin-bottom: 30px;
    padding-bottom: 20px;
  }
}

/* 630px and below */
@media screen and (max-width: 630px) {

  /* -- Homepage Search -- */
  #home-search {
    padding-top: 44px;

    .home-search-cont {
      h2 {
        font-size: 2.250em;
      }

      #views-exposed-form-product-catalog-page {

        .views-widget-filter-search_api_views_fulltext {
          width: calc(100% - 160px);
        }

        .views-submit-button {
          width: 160px;

          input.form-submit {
            padding: 0 28px;
          }
        }
      }
    }
  }

  /* Admin */
  #user-login .form-item input {
    width: 90%;
  }

  #user-register-form {
    input#edit-name,
    input#edit-mail,
    .field-name-field-profile-name input {
      width: 90%;
    }
  }

  #edit-account {
    #edit-timezone--2 {
      width: 100%;
    }
  }
}

/* 590px and below */
@media screen and (max-width: 590px) {

  /* -- Homepage Featured CTA -- */
  #homepage-bottom-content #bottom-left {
    .view-ctas .view-content {
      display: block;

      .views-row.views-row-2 {
        margin: 0 auto;
      }

      .views-row {
        display: block;
        max-width: 240px;
        margin: 0 auto;
      }
    }
  }
}

/* 550px and below */
@media screen and (max-width: 550px) {

  /* -- Top Bar -- */
  #top-bar {

    .container {
      padding: 0;
    }

    .top-bar-left {
      display: block;
      width: 100%;
      text-align: center;
      padding-bottom: 5px;
    }

    .top-bar-right {
      clear: both;
      padding: 0 0 0 8px;
      text-align: center;
    }

    a.top-bar-cart {
      display: block;
      float: none;
      right: 0;

      #b-header-view-cart {
        text-align: center;
      }
    }
  }

  #site-header {
    padding: 28px 0 24px;
  }

  /* -- Homepage Search -- */
  #home-search {
    padding-top: 40px;

    .home-search-cont {
      padding: 20px 30px 90px;

      #views-exposed-form-product-catalog-page {

        .views-widget-filter-search_api_views_fulltext {
          width: 100%;

          .form-item-search-api-views-fulltext input.form-text {
            text-align: center;
          }
        }

        .views-submit-button {
          width: 100%;
          padding: 10px 0 0 0;
          text-align: center;

          input.form-submit {
            padding: 0;
            width: 200px;
          }
        }
      }
    }
  }

  /* Layout */

  #site-wrapper.page-content #site-content #page-title {
    padding-top: 0;
  }

  #pri-nav-cont ul.menu {
    display: none;
  }

  #menu-dropdown {
    display: block;
    visibility: visible;
  }

  #sec-nav-cont {
    display: none;
    visibility: hidden;
  }

  header #b-header-view-cart {
    text-align: left;
  }

  #footer-text #footer-acro-copy {
    float: left;
  }

  #footer-nav-cont {
    float: left;
  }

  #contact-us-map {
    display: none;
  }

  /* Commerce */

  #product-images {
    width: 100%;
  }

  #product-details {
    float: none;
  }

  .view-commerce-cart-form table .views-field-field-product-image {
    display: none;
  }

  .reviews-overall-rating {
    clear: both;
    float: left;
  }

  .comment-header-cont .comment-rating {
    clear: both;
  }
}

/* 500px and below */
@media screen and (max-width: 500px) {

  /* -- Commerce -- */
  .view-product-catalog ul.pager {

    li.pager-ellipsis, li.pager-previous, li.pager-next {
      display: none;
    }

    li.pager-current {
      color: $orange;
    }
  }

  /* Related Products */
  #product-page-related-products .product-category {
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }
}

/* 450px and below */
@media screen and (max-width: 450px) {

  /* -- Homepage Search -- */
  #home-search {
    padding-top: 10px;
    height: 245px;
    background-image: none;

    .home-search-cont {
      padding: 15px 10px 80px;

      h2 {
        font-size: 1.65em;

      }

      #views-exposed-form-product-catalog-page .views-widget-filter-search_api_views_fulltext .form-item-search-api-views-fulltext input.form-text {
        font-size: 1.1em;
        padding: 0 10px;
      }
    }
  }

  /* -- Homepage CTA link below Featured CTA -- */
  .region-featured-bottom-cta .content {
    display: none;
  }

  /* -- Footer -- */
  #ft-top {
    .footer-logo {
      margin: 0 auto;
      display: block;
    }

    .footer-social {
      float: none;
      width: 100%;

      ul.menu {
        text-align: center;
      }
    }
  }

  #ft-bot .footer-links ul.menu li:before {
    padding: 0 5px 0 2px;
  }
}

/* 400px and below */
@media screen and (max-width: 400px) {
  /* Header */
  #site-header #site-logo {
    width: 200px;
  }

  .slideout-menu-toggle {
    margin-top: 9px;
  }

  /* Footer */
  #ft-bot .footer-links ul.menu li:before {
    padding: 0 5px 0 2px;
  }

  /* -- Product Specs -- */
  #product-tab-specs .specs-wrapper {
    .field-label {
      width: 100%;
    }

    .field-items {
      width: 100%;
    }
  }
  
  /* -- Product Location -- */
  #product-tab-location .location-wrapper {
    .field-label {
      width: 100%;
    }

    .field-items {
      width: 100%;
    }
  }
  #product-tabs-cont ul li {
    margin: 0 2px 0 0;
    a {
      padding: 5px;
      font-size: 1rem;
    }
  }
}


/* 360px and below */
@media screen and (max-width: 360px) {

  /* clear image floats */
  .content img {
    float: none !important;
    display: inline-block !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  #user-login input.form-text {
    width: 95%;
  }

  /* Commerce */
  #commerce-checkout-form-checkout #edit-buttons, #commerce-checkout-form-review {
    input {
      font-size:13px;
    }
  }

  /* Admin */
  #edit-account {
    .content input.form-text, .content textarea {
      max-width: 255px;
    }
  }
}

/* 320px and below */
@media screen and (max-width: 320px) {
  #product-images-nav-cont-slider {
    width: 75%;
  }
}
