/**
 * Webforms
 */

.form-type-radio .description, .form-type-checkbox .description {
  margin-left: 1.2em;
}

#webform-client-form-7 .form-required {
  color: $orange;
}

.webform-client-form {

  .form-text, .form-textarea {
    background: #fff;
    color: #666;
    padding: 5px;
    width: 90%;
  }

  .webform-component-textarea .grippie {
    width: 90%;
    margin-left: 6px;
  }
}

.content {

    input.form-text, textarea {
    display: inline-block;
    padding: 5px 10px;
    margin: 0;
    background: #fff;
    text-decoration: none;
    color: #666;
    border: 1px solid #ccc;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    font-size: 1em;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }

  input.form-text.error {
    border: 2px solid red;
  }
}

#edit-commerce-coupon-coupon-code {
  width: 100%;
  max-width: 250px;
}

#node-151 .field-name-body {
  max-width: 700px;
}

#webform-client-form-151 {
  max-width: 700px;

  .form-item-submitted-requested-part-details-urgency {
    display: inline-block;
    padding-right: 25px;
  }
}

/* Hide Honeypot */
.url-textfield {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}
