.profile h3 {
  margin-top: 20px;
}

/* -- Admin Tabs -- */
div.tabs {
  margin: 0;
  padding: 0;
}
div.tabs ul.primary {
  border: 0;
  padding: 0;
  margin: 0;
}
div.tabs ul.primary li {
  display: block;
  float: left;
  margin: 0;
  padding: 0 0 10px 0;
}
div.tabs ul.primary li a {
  position: relative;
  margin: 0 5px 0px 0;
  padding: 6px 10px 4px 10px;
  display: block;
  float: left;
  border: 0;
  background: #e9e9e9;
  font-size: 0.9em;
  color: #999;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  behavior: url(/pie/PIE.htc);
}
div.tabs ul.primary li a:hover {
  color: #fff;
  background: $blue;
  -webkit-transition: background 0.15s linear;
  -moz-transition: background 0.15s linear;
  -ms-transition: background 0.15s linear;
  -o-transition: background 0.15s linear;
  transition: background 0.15s linear;
}
div.tabs ul.primary li a.active {
  color: #fff;
  background: $orange;
}
