/**
 * Left Column Search
 */
#site-left-col .region-homepage-search {
  .block {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ccc;
  }

  h2 {
    font-size: 1.375rem;
  }

  input.form-text {
    width: 100%;
    font-size: 13px;

  }

  .form-submit {
    margin-top: 15px;
  }
}

/**
 * Product Filter Facets
 */

#product-filters, #mobile-product-filters {
  .block-facetapi {
    border-top: 1px solid $gray-border;
    padding-top: 13px;

    &:first-of-type {
      border-top: none;
      padding-top: 0;
    }

    h2 {
      font-size: 1.375rem;
    }

    ul li {
      margin: 0;
      padding: 0 6px;
      list-style: none;
      -webkit-transition: all 0.25s;
      -moz-transition: all 0.25s;
      -ms-transition: all 0.25s;
      -o-transition: all 0.25s;
      transition: all 0.25s;

      &:hover {
        background-color: #f0f0f0;
      }

      input[type="checkbox"], input[type="radio"] {
        top: 1px;
        position: relative;
      }
    }
  }
}



#mobile-product-filters-cont {
  display: none;
  min-height: 32px;

  #mobile-block-toggle-open, #mobile-block-toggle-close {
    text-align: center;

    a {
      text-align: center;

      i {
        margin-left: 4px;
      }

      &:hover, &:focus {
        color: #fff;
        text-decoration: none;
      }
    }
  }

  #mobile-block-toggle-open {
    position: relative;
    z-index: 2;

    a {
      -webkit-border-radius: 0 0 5px 5px;
      border-radius: 0 0 5px 5px;
    }
  }

  #mobile-block-toggle-close {
    a {
      -webkit-border-radius: 5px 5px 0 0;
      border-radius: 5px 5px 0 0;
    }
  }

  #mobile-product-filters {
    display: none;
    position: relative;
    z-index: 1;
    padding: 20px 20px 0;
    background-color: $body-bg;
    border-bottom: 1px solid $gray-border;

    .visible {
      display: block;
    }
  }
}



/**
 * Product Category Listings
 */

.view-product-catalog {
  .views-row {
    border: 1px solid $gray-border;
    margin: 0 0 30px 0;
    width: 24%;
    text-align: center;
    vertical-align: top;
    overflow: hidden;
    box-sizing: border-box;
    display: inline-block;

    @media screen and (max-width: 1050px) { width: 32%; }
    @media screen and (max-width: 600px) { width: 49%; }
    @media screen and (max-width: 400px) { width: 100%; }

    a {
      cursor: pointer;
    }

    .views-field-field-product-image {
      height: 100px;
      padding: 15px;

      a {
        opacity: 1;

        &:hover, &:focus {
          opacity: 0.75;
        }
      }
    }

    .views-field-title {
      height: 48px;
      padding: 0 10px 10px;
      overflow: hidden;

      @media screen and (max-width: 400px) {
        height: 25px;

        h4 {
          font-size: 1rem;
        }
      }
    }

    .views-field-field-product-commerce-price-amount {
      height: 28px;
      padding: 0 10px 5px;
      font-size: 1.25em;
      color: $orange;
    }

    .views-field-view-node a {
      display: block;
      padding: 3px 10px;
      margin: 0;
      background: $orange-light;
      text-decoration: none;
      color: #fff;
      font-weight: bold;

      &:hover, &:focus {
        background-color: $orange;
      }
    }
  }

  ul.pager {
    padding-top: 40px;

    li.pager-current {
      color: $orange;
    }
  }
}


/**
 * Product Pages
 */

.commerce-product-extra-field-title {
  display: none;

  .commerce-product-title-label {
    display: none;
  }
}

#product-details .commerce-product-extra-field-title {
  display: block;

  .commerce-product-title-label {
    display: none;
  }
}


.product-title {
  padding-bottom: 0;
}

#product-content {
  padding-top: 14px;
}

#product-images {
  float: left;
  position: relative;
  width: 475px;
  margin: 0 30px 10px 0;

  #product-images-cont {
    position: relative;
    max-width: 475px;
    height: 300px;
    margin: 0 0 10px 0;
    padding: 20px;
    border: 1px solid #ccc;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        float: left;
        position: relative;
        display: none;
        padding: 0;
        margin: 0;
        text-align: center;

        a {
          display: block;
        }
      }
    }

    .product-images-zoom {
      position: absolute;
      display: block;
      bottom: 0;
      right: 0;
      width: 37px;
      height: 31px;
      background: url(../gfx/bg_product_zoom_icon.png) no-repeat;
    }
  }

  #product-main-image {
    border: #ebebeb solid 10px;

    img {
      display: block;
    }
  }
}

#product-thumbnails {
  margin: 10px 0;
  overflow: hidden;
  width: 300px;

  a {
    border: #ebebeb solid 4px;
    display: block;
    float: left;
    height: 35px;
    margin: 0 10px 5px 0;
    width: 35px;
  }
}

#product-details {
  float: left;
  width: calc(100% - 520px);

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      padding: 0;
      margin: 0;

      &.label-product-title {
        border-bottom: 1px solid $gray-border;
      }

      &.label-product-learn-more {
        margin: 0 0 20px 0;
      }

      &.label-product-add-to-cart .form-text {
        position: relative;
        background: #fff;
        text-align: center;
        -webkit-border-radius: 5px;
        border-radius: 5px;
        behavior: url(/pie/PIE.htc);
      }

      &.label-product-social-sharing {
        padding: 0 0 10px 0;

        img {
          display: none !important;
        }

        .stButton {
          margin: 0;
        }

        span {
          display: inline-block;
          padding: 0 1px 0 0;

          a {
            display: inline-block;
            width: 24px;
            height: 24px;
            padding: 0;
            margin: 0;
            line-height: 24px;
            text-align: left;
            text-indent: -9999px;
            outline: none;
            overflow: hidden;
            background: url(../gfx/m_social_media.png) no-repeat;
            cursor: pointer;

            &:hover {
              opacity: 0.75;
            }
          }

          &.st_facebook_custom a {
            background-position: 0 0;
          }

          &.st_twitter_custom a {
            background-position: -24px 0;
          }

          &.st_pinterest_custom a {
            background-position: -48px 0;
          }

          &.st_email_custom a {
            background-position: -72px 0;
          }

          &.st_sharethis_custom a {
            background-position: -96px 0;
          }
        }
      }

      &.label-product-price {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        font-size: 1.5em;
        border-bottom: 1px solid $gray-border;
        padding: 10px 15px;
        color: #000;
        background-color: #ededed;

        .field-item {
          margin-right: 20px;
        }

        .shipping-included {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;

          color: $gray;
          font-size: 16px;

          i {
            margin-right: 5px;
          }

          &.false {
            text-decoration: line-through;
          }
        }

        tbody {
          border: none;
        }

        .commerce-price-savings-formatter-prices {
          tr {
            display: inline-block;
            padding: 0;
            margin: 0;
            border: none;

            td {
              font-size: 1.43em;
              vertical-align: bottom;
              padding: 0;
              margin: 0;
            }

            &.commerce-price-savings-formatter-list td {
              font-size: 1em;
              color: #7a7a7a;
              padding: 6px 5px 0 0;
            }

            &.commerce-price-savings-formatter-price .price-label {
              display: none;
            }

            &.commerce-price-savings-formatter-price .price-amount {
              color: #000;
              padding: 0 5px 0 0;
            }
          }
        }
      }

      &.label-product-model {
        padding: 0 5px;
      }

      &.label-product-add-to-cart {
        padding: 0 0 27px 0;

        a {
          margin-top: 20px;
          text-decoration: none;
        }

        a:hover {
          color: white;
        }

        .form-item-quantity {
          margin-bottom: 20px;
        }

        .form-submit {
          font-size: 1.5em; /* 24 */
        }
      }
    }
  }

  .mcta {
    background-color: #eee;
    border: #ccc solid 1px;
    margin: 20px 0 10px 0;
    padding: 0;
    width: 218px;

    p {
      color: #000;
      margin: 0 0 20px 0;
      padding: 20px 0 0 0;
      text-align: center;
    }
  }
}

#product-body-cont {
  clear: both;
  padding: 10px 0 0 0;
}

/* -- Product Images - Nav -- */
#product-images-nav-cont-slider {
  margin: 0 auto;
  width: 410px;
}
#product-images-nav-cont {
  position: relative;
  clear: both;
  height: 37px;
  max-width: 475px;
  overflow: hidden;
}
#product-images-nav-cont ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#product-images-nav-cont ul li {
  float: left;
  display: block;
  width: 37px;
  height: 37px;
  margin: 0 5px 0 0;
  padding: 0;
  background: none;
}
#product-images-nav-cont ul li a {
  display: block;
  width: 37px;
  height: 37px;
  cursor: pointer;
  text-decoration: none;
}
#product-images-nav-cont ul li a img {
  display: block;
  width: 35px;
  height: 35px;
  border: 1px solid #ccc;
}
#product-images-nav-cont ul li.flex-active-slide a img {
  border-color: #000;
}
#product-images-nav-cont ul li a:hover img {
  border-color: #000;
}
#product-images-nav-cont .product-images-nav-cont-slider-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#product-images-nav-cont .product-images-nav-cont-slider-nav ul li {
  float: none;
  margin: 0;
  padding: 0;
  background: none;
  width: auto;
  height: auto;
}
#product-images-nav-cont .product-images-nav-cont-slider-nav ul li a.flex-prev {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 22px;
  height: 40px;
  cursor: pointer;
  text-align: left;
  text-indent: -9999px;
  outline: none;
  overflow: hidden;
  background: url(../gfx/b_image_gallery_nav_prev.png) 0 0 no-repeat;
}
#product-images-nav-cont .product-images-nav-cont-slider-nav ul li a.flex-prev:hover {
  background-position: 0 -40px;
}
#product-images-nav-cont .product-images-nav-cont-slider-nav ul li a.flex-next {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  width: 22px;
  height: 40px;
  cursor: pointer;
  text-align: left;
  text-indent: -9999px;
  outline: none;
  overflow: hidden;
  background: url(../gfx/b_image_gallery_nav_next.png) 0 0 no-repeat;
}
#product-images-nav-cont .product-images-nav-cont-slider-nav ul li a.flex-next:hover {
  background-position: 0 -40px;
}
#product-images-nav-cont .product-images-nav-cont-slider-nav ul li a.flex-disabled {
  opacity: 0.3;
  filter: alpha(opacity=30); /* thanks ie */
}

/* -- Product Tabs -- */
#product-page-tabs {
  background: none !important;
  border: none !important;
  color: inherit !important;
  font-family: $font-family;
}

.product-page-tabs-content {
  padding: 0 0 5px;
  font-size: 1rem;
  border-bottom: 1px solid $gray-border;
  margin-bottom: 40px;
}

#product-tabs-cont {
  clear: both;
  display: block;
  margin: 0 0 15px 0;
  border-bottom: 1px solid $gray-border;

  ul {
    position: relative;
    margin: 0 0 0 10px;
    padding: 0;
    list-style: none;
    background: none !important;
    border: none !important;
    color: inherit !important;

    li {
      float: left;
      margin: 0 5px 0 0;
      padding: 0;
      background: none !important;
      border: none !important;
      color: inherit !important;

      a {
        position: relative;
        display: block;
        top: 1px;
        padding: 6px 10px 5px 10px;
        margin: 0;
        font-size: 1.125rem; /* 18px */
        font-weight: bold;
        text-decoration: none;
        background-color: #ededed;
        color: $gray;
        border: 1px solid $gray-border;
        -webkit-border-radius: 5px 5px 0px 0px;
        border-radius: 5px 5px 0px 0px;
        behavior: url(/pie/PIE.htc);
        outline: none;

        &:hover {
          color: $blue;
        }
      }

      &.ui-state-active a {
        background-color: #fff;
        color: $blue;
        border-bottom: 1px solid #fff;
      }
    }
  }
}

/* -- Product Tabs - Tab Content -- */
#product-page-tabs #product-tab-specs ul {
  padding: 0;
  margin: 0;
  background: none;

  li {
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    display: none;
    text-transform: uppercase;

    a {
      text-decoration: none;
      padding: 0;
      margin: 0;
      background: none;
      border: none;

      &:hover {
        color: #ec7930;
      }
    }

    &.pager-previous {
      display: block;
      float: left;
    }

    &.pager-next {
      display: block;
      float: right;
    }
  }
}

#product-body-cont .ui-tabs-panel {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}

/* -- Product Specs -- */
#product-tab-specs {
  .specs-wrapper {
    border: 1px solid #ccc;
    margin-bottom: 8px;

    &>div {
      padding: 5px 10px;

      &:nth-of-type(even) {
        background-color: #ededed;
      }
    }

    .field-label {
      display: inline-block;
      width: 30%;
      vertical-align: top;
    }

    .field-items {
      display: inline-block;
      width: 68%;
      vertical-align: top;
    }
  }
}

/* -- Product Location -- */
#product-tab-location {
  .location-wrapper {
    border: 1px solid #ccc;
    margin-bottom: 8px;

    &>div {
      padding: 5px 10px;

      &:nth-of-type(even) {
        background-color: #ededed;
      }
    }

    .field-label {
      display: inline-block;
      width: 30%;
      vertical-align: top;
    }

    .field-items {
      display: inline-block;
      width: 68%;
      vertical-align: top;
    }
  }
}

/* -- Related Products -- */
#product-page-related-products .product-category {
  float: left;
  width: 22%;
  min-height: 181px;
  margin: 0 13px 13px 0;
  text-align: center;
}
#product-page-related-products .product-category .cat-image {
  clear: both;
  width: 100%;
}
#product-page-related-products .product-category .cat-image img {
  height: 83px;
}
#product-page-related-products .product-category .cat-data {
  float: none;
  clear: both;
  width: auto;
  padding: 1px 0 0 0;
}
#product-page-related-products .product-category .cat-data h2 {
  font-size: 1.2em;
  min-height: 53px;
}
#product-page-related-products .product-category .cat-data p {
  display: none;
}
#product-page-related-products .product-category .cat-data .commerce-price-savings-formatter-prices tr td {
  text-align: center;
}

/**
 * Commerce Styles
 */

.checkout-buttons .checkout-cancel, .checkout-buttons .checkout-back {
  color: $blue;
}
.checkout-buttons .checkout-cancel:hover, .checkout-buttons .checkout-back:hover {
  color: $blue-dark;
  text-decoration: underline;
}

/**
 *   FOOTABLE STYLES
 */
.footable.breakpoint tbody .footable-first-column {
  background:url('../gfx/plus.png') 8px center no-repeat;
  padding-left:37px;
}
.footable.breakpoint tbody .footable-detail-show .footable-first-column {
  background:url('../gfx/minus.png') 8px center no-repeat;
}
.footable.breakpoint tbody .footable-visible {
  cursor:pointer;
}
.footable-row-detail{
  background:#eeeeee;
  border-bottom:1px solid #cecece;
}


/**
 * Commerce Cart / Summary
 */
#views-form-commerce-cart-form-default {
  overflow-y: hidden;
  padding-bottom: 10px;
}

#commerce-checkout-form-checkout #edit-cart-contents .fieldset-wrapper {
  overflow-y: hidden;
  width: 100%;
}

.views-table {
  min-width: 550px;
  tr.has-warranty {
    border: none;
  }
  tr.warranty-row {
    border-bottom: 1px solid #ccc;
    td.align-right {
      text-align: right;
    }
    td.align-left {
      text-align: left;
    }
    td.align-center {
      text-align: center;
    }
  }
}

.page-checkout #commerce-checkout-form-checkout .views-table {
  min-width: 100%;
}

.view-commerce-cart-form, .view-commerce-cart-summary {

  table {
    width: 100%;

    thead {
      tr {
        background-color: #ededed;
      }
    }

    tbody {
      border: none;
      font-size: 0.875em; /* 14px */

      tr {
        background-color: #fff;
      }
    }

    th {
      padding: 10px;
      border-bottom: 1px solid #ccc;
      vertical-align: bottom;
    }

    td {
      padding: 10px;
      vertical-align: middle;
    }

    .commerce-price-savings-formatter-prices {

      tr {
        border: none !important;
      }

      td {
        text-align: right;
        padding: 0;
      }

      .price-amount {
        color: $gray;
      }
    }

    input.delete-line-item {
      font-size: 1em;
      padding: 2px 6px;
    }
  }

  .line-item-summary .line-item-total {
    padding: 10px;
  }
}

/* -- Cart Specific (cart and summary shared css above) -- */
.view-commerce-cart-form {

  #edit-actions input {
    margin: 0 0 0 5px;
  }

  table .views-field-edit-quantity input {
    text-align: center;
  }
}

/* -- Summary Specific (cart and summary shared css above) -- */
.view-commerce-cart-summary {
  .view-footer {
    padding: 10px 0 0 0;
    background: #ccc;
  }

  .component-type-commerce-price-formatted-amount td {
    background: $blue-dark;
    color: #fff;
  }
}

/* -- Other (cart and summary shared css above) -- */

.content .views-field-edit-quantity .form-text {
  position: relative;
  display: inline-block;
  padding: 0 8px;
  margin: 0;
  border: 1px solid #ccc;;
  background: #fff;
  text-decoration: none;
  color: $gray;
  font-weight: normal;
  height: 24px;
  line-height: 24px;
  font-size: 1.2em;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  behavior: url(/pie/PIE.htc);
}


/**
 * Commerce Checkout
 */

.page-checkout {

  #commerce-checkout-form-checkout {

    .addressfield-container-inline .form-item {
      min-height: 48px;
    }

    table {
      width: 100%;
    }

    #edit-buttons input {
      margin-right: 5px;
    }

    select#edit-customer-profile-billing-commerce-customer-address-und-0-country {
      width: 267px
    }

    select#edit-customer-profile-shipping-commerce-customer-address-und-0-country {
      width: 267px
    }
  }
}

.button-operator {
  padding-left: 5px;
}

.form-item-customer-profile-shipping-commerce-customer-address-und-0-country {
  max-width:300px;
}

/* -- Commerce Checkout - Review -- */
#edit-checkout-review {
  margin: 0 0 20px 0;
}

#commerce-checkout-form-review {

  .checkout-help {
    padding-bottom: 20px;
  }

  table {
    width: 100%;

    tr {
      background-color: #fff;

      &.pane-title {
        background-color: #ededed;
        color: $blue;
        font-size: 1.125em;
      }
    }
  }

  table.checkout-review .pane-data-full {
    padding: 6px 10px 30px;
  }


  #edit-buttons input {
    margin-right: 5px;
  }

  .pane-title td {
    padding: 10px;
  }

  .view-commerce-cart-summary {
    padding: 0 0 10px 0;
  }

  .form-item-commerce-payment-payment-details-moneris-credit-card-number {

    input.form-text {
      width: auto;
    }

    .description {
      padding-bottom: 30px;
      background: url(../gfx/cards_accepted.gif) 0px 2em no-repeat;
    }
  }

  .form-item-commerce-payment-payment-details-moneris-credit-card-cvd-value input.form-text {
    width: auto;
  }
}

/**
 * User Menu Commerce Orders
 */

.view-commerce-user-orders table {
  width: 100%;
  font-size: 0.875em; /* 14px */;

  thead {
    tr td {
      padding: 5px 10px;
    }
  }

  tbody {

     tr {
       background-color: #fff;

       td {
         padding: 5px 10px;
       }

       td.active {
         background: #ededed;
       }
     }
  }
}

.entity-commerce-order {
  .field-name-commerce-line-items,
  .field-name-commerce-order-total {
    padding-bottom: 30px;

    table {
      font-size: 0.875em; /* 14px */;

      thead {
        tr {
          background-color: #ededed;

          th {
            padding: 5px 10px;
          }
        }
      }

      tbody {
        tr {
          background-color: #fff;

          td {
            padding: 5px 10px;
          }

          td.active {
            background: #ededed;
          }
        }
      }
    }
  }

  .field-name-commerce-line-items table {
    width: 100%;
  }

  .field-name-commerce-order-total table {
    max-width: 435px;
    width: 100%;

    tbody tr.component-type-commerce-price-formatted-amount {
      background-color: $blue-dark;
      color: #fff;
    }
  }

  .field-name-commerce-customer-billing,
  .field-name-commerce-customer-shipping {
    border-top: 1px solid $gray-border;
    padding: 20px 0;

    .field-label {
      font-size: 1.125em;
      color: $blue;
    }
  }
}
/*ESD device policy popup*/
#cboxClose {
  top: 0;
}

#cboxOverlay {
  background-color: #ccc !important;
}