/**
 * Top Bar
 */

#top-bar {
  min-height: 40px;
  background-color: #ededed;
  border-bottom: 1px solid $gray-border;

  /* -- Left Block -- */

  .top-bar-left {
    display: inline-block;
    color: $blue-dark;
    padding: 7px 0 0 0;

    &::before {
      background-image: url(../gfx/icon-globe.png);
      background-size: 27px 27px;
      display: inline-block;
      width: 27px;
      height: 27px;
      content: "";
      margin: 0 5px -7px 0;
    }

    &::after {
      color: $orange;
      font-family: FontAwesome;
      content: "\f054";
      font-size: .65em;
      font-weight: normal;
    }

    .region-header-top-left {
      display: inline-block;

      a {
        color: $orange;

        &:hover, &:focus {
          color: $orange-light;
        }
      }

      p {
        margin: 0;
        font-size: 1.05em;
      }
    }
  }

  /* -- Right Block - Currency Selector -- */

  .top-bar-right {
    display: inline-block;
    float: right;
    padding-right: 5px;

    .login-link {
      display: inline-block;
      padding: 6px 25px 6px 0;
      margin-right: 19px;
      border-right: 1px solid #ccc;
    }

    .region-header-top-right {
      display: inline-block;

      .form-item-selected-currency {
        position: relative;

        &:after {
          content: "\f078";
          font-family: "FontAwesome";
          font-size: 0.9em;
          color: $blue-dark;
          right: 5px;
          top: 1px;
          padding: 0;
          position: absolute;
          pointer-events: none;
        }
      }

      #commerce-multicurrency-selector-form {
        select {
          padding: 0;
          margin: 0;
          background: none;
          color: $blue-dark;
          font-family: $font-family;
          font-weight: bold;
          border:none;
          outline:none;
          display: inline-block;
          -webkit-appearance:none;
          -moz-appearance:none;
          appearance:none;
          cursor:pointer;
          -webkit-border-radius: 0px;
          -moz-border-radius: 0px;
          border-radius: 0px;
          width: 58px;
        }
      }
    }
  }

  /* -- View Cart -- */

  a.top-bar-cart {
    display: inline-block;
    font-weight: bold;
    float: right;
    text-decoration: none;
    right: -8px;
    position: relative;


    #b-header-view-cart {
      text-align: right;
      font-size: 1.05em;
      color: #fff;
      box-sizing: border-box;
      height: 41px;
      padding: 8px 16px 0;
      background: $orange;
      background: -moz-linear-gradient(top, $orange-light 0%, $orange 100%);
      background: -webkit-linear-gradient(top, $orange-light 0%, $orange 100%);
      background: linear-gradient(to bottom, $orange-light 0%, $orange 100%);

      img {
        padding: 0 6px 3px 0;
      }

      .cart-quantity {
        color: $blue-dark;
        display: inline-block;
        height: 24px;
        width: 24px;
        margin-left: 5px;
        text-align: center;
        font-size: 0.875em; /* 14px */
        background-color: #fff;
        @include border-radius(12px);
      }
    }

    &:hover, &:focus {
      #b-header-view-cart {
        background: $orange-light;
        background: -moz-linear-gradient(top, $orange 0%, $orange-light 100%);
        background: -webkit-linear-gradient(top, $orange 0%, $orange-light 100%);
        background: linear-gradient(to bottom, $orange 0%, $orange-light 100%);
      }
    }
  }
}

/**
 * Header
 */

#site-header {
  padding: 28px 0 24px;
  border-bottom: 10px solid $orange-light;
  box-sizing: border-box;

  #site-logo {
    margin: 0;
    padding: 0;
    width: 284px;
    display: inline-block;
  }
}

/* -- Primary Navigation -- */

#pri-nav-cont {
  width: auto;
  padding: 40px 23px 0 0;
  float: right;
  display: inline-block;

  ul.menu {
    text-align: right;
    margin: 0;
    padding: 0;

    /* Main Links */

    &:first-child > li {
      padding: 0 0 16px 0;
      margin: 0;
      display: inline-block;
      *display: inline;
      zoom: 1;
      background: none;
      font-weight: bold;

      a {
        display: inline-block;
        *display: inline;
        zoom: 1;
        margin: 0 0 0 23px;
        padding: 3px 0 3px 27px;
        font-size: 1.375em; /* 22px */
        white-space: nowrap;
        border-left: 2px solid #ededed;

        &:hover, &:focus {
          text-decoration: none;
        }

        &:hover > ul.menu, &:focus > ul.menu {
          opacity: 1;
        }

        &.active {
          color: $orange;
        }
      }

      &.first a {
        padding-left: 0;
        margin-left: 0;
        border-left: none;
      }

      &.last a::after {
        font-family: FontAwesome;
        content: "\f078";
        font-size: .65em;
        font-weight: normal;
        padding-left: 8px;
        position: relative;
        bottom: 3px;
      }

      &.expanded {
        position: relative;
      }

      /* Dropdown */

      &.expanded > span > ul.menu {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 40px;
        right: 0;
        min-width: 74%;
        padding: 7px 15px 6px 6px;
        text-align: left;
        background-color: rgba(255, 255, 255, 0.9);
        border: 1px solid rgba(0, 0, 0, 0.1);
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
        z-index: 10;

        &.touch-hover {
          opacity: 1;
          pointer-events: all;
        }

        li {
          padding: 0 0 5px 5px;
          margin: 0;
          display: block;
          zoom: 1;
          background: none;
          color: $blue;
          font-size: 0.800em; /* 16px */
          width: 100%;

          a {
            margin: 0;
            padding: 0;
            font-weight: normal;
            border-left: none;

            &.active-trail {
              color: $orange;
            }

            &::after {
              content: "";
              padding: 0;
            }
          }
        }
      }

      &:hover > span > ul.menu, &:focus > span > ul.menu {
        opacity: 0;
        pointer-events: none;
      }

      &.last:hover > span > ul.menu {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}

