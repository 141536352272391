/**
 * Homepage Top CTA
 */

#top-ctas {
  background-color: #ededed;
  border-bottom: 1px solid $gray-border;
  padding: 20px 0 12px;

  .view-ctas .view-content {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }

  .views-row {
    display: -webkit-inline-flex;
    display: inline-flex;
    box-sizing: border-box;
    width: 100%;
    margin: 0;

    &.views-row-2 {
      margin: 0 1.5%;
    }

    a.top-cta-link {
      display: inline-block;
      width: 100%;
      background-color: #f8f8f8;
      border-bottom: 1px solid $gray-border;
      padding: 20px 30px 30px;
      box-sizing: border-box;
      @include border-radius(3px);

      .top-cta-icon {
        float: left;
        width: 90px;
      }

      .top-cta-text {
        float: right;
        width: calc(100% - 90px);
        padding-left: 20px;
        box-sizing: border-box;
      }

      &:hover, &:focus {
        background-color: #fff;

        h2 {
          color: $blue-dark;
        }

        .btn-element {
          background: $orange;
        }
      }
    }
  }
}

/**
 * Homepage Middle CTA
 */

#middle-cta {
  padding: 24px 0 30px;
  background: url(../gfx/shadow-bottom.png) bottom center no-repeat transparent;

  .container {
    -webkit-box-shadow: 0px 40px 90px -86px rgba(0, 0, 0, 0.85);
    -moz-box-shadow: 0px 40px 90px -86px rgba(0, 0, 0, 0.85);
    box-shadow: 0px 40px 90px -86px rgba(0, 0, 0, 0.85);
  }

  .region-homepage-middle-cta {
    text-align: center;

    h2 {
      font-size: 1.875em; /* 30px */
      font-weight: normal;
      padding-bottom: .2em;
    }

    .content {
      font-size: 1.250em; /* 20px */

      a {
        color: $orange;

        &:hover, &:focus {
          color: $orange-light;
        }
      }
    }
  }
}

/**
 * Homepage Featured CTA
 */

#homepage-bottom-content #bottom-left {

  .view-ctas .view-content {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-shadow: 0px 40px 90px -86px rgba(0, 0, 0, 0.85);
    -moz-box-shadow: 0px 40px 90px -86px rgba(0, 0, 0, 0.85);
    box-shadow: 0px 40px 90px -86px rgba(0, 0, 0, 0.85);
  }

  .views-row {
    display: -webkit-inline-flex;
    display: inline-flex;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 0 0 40px;

    &.views-row-2 {
      margin: 0 3.5%;
    }

    a.featured-cta {
      display: inline-block;
      width: 100%;
      box-sizing: border-box;

      .featured-cta-image {
        width: 100%;
        text-align: center;
        background-color: #c1c1c1;
        border: solid #c1c1c1;
        border-width: 1px 1px 0;
        box-sizing: border-box;
        opacity: 1;
        -webkit-transition: all 0.25s;
        -moz-transition: all 0.25s;
        -ms-transition: all 0.25s;
        -o-transition: all 0.25s;
        transition: all 0.25s;
      }

      .featured-cta-title {
        width: 100%;
        text-align: center;
        padding-top: 7px;
        background: $orange-light;
        background: -moz-linear-gradient(top, $orange-light 50%, $orange 51%);
        background: -webkit-linear-gradient(top, $orange-light 50%, $orange 51%);
        background: linear-gradient(to bottom, $orange-light 50%, $orange 51%);

        h3 {
          font-size: 1.625rem;
          color: #fff;
        }
      }

      .featured-cta-link {
        width: 100%;
        text-align: center;
        font-size: 1.125em; /* 18px */
        padding-top: 7px;
        color: $orange-light;

        span {
          color: $blue;
        }
      }

      &:hover, &:focus {
        text-decoration: none;

        .featured-cta-image {
          opacity: 0.8;
        }

        .featured-cta-title {
          background: $orange;
          background: -moz-linear-gradient(top, $orange 50%, $orange-light 51%);
          background: -webkit-linear-gradient(top, $orange 50%, $orange-light 51%);
          background: linear-gradient(to bottom, $orange 50%, $orange-light 51%);

          h3 {
            text-decoration: none;
          }
        }

        .featured-cta-link {
          color: $orange;

          span {
            color: $blue-dark;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

/**
 * Homepage CTA link below Featured CTA
 */

.region-featured-bottom-cta {
  padding: 47px 0 28px;
  border-bottom: 1px solid $gray-border;

  .content {
    text-align: center;
    font-size: 1.125em; /* 18px */
    letter-spacing: 0.02em;
    color: $blue;

    p {
      display: inline-block;
      clear: both;

      &::before {
        font-family: FontAwesome;
        content: "\f002";
        color: $orange;
        font-size: 1.2em;
        font-weight: normal;
        padding-right: 8px;
        position: relative;
        top: 0;
      }
    }
  }
}
