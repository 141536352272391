/**
 * Secondary Side Nav
 */

#sec-nav-cont {
  margin: 5px 0 30px 0;

  a {
    text-decoration: none;
  }

  #h_sec_nav {
    display: block;
    color: #000;
    font-size: 2.33em;
    letter-spacing: 3px;
    margin: 0 0 10px;
    padding: 0;
    text-transform: uppercase;
    text-decoration: none;
  }

  ul.menu {
    margin: 0;
    padding: 0;

    &:first-child > li {
      background-image: none;
      margin-bottom: 2px;
      margin-left: 0;
      padding-left: 0;
      display: inline-block;
      *display: inline;
      zoom: 1;
      border-bottom: 1px solid $gray-border;
      width: 100%;

      a {
        color: $blue;
        display: block;
        font-size: 1.125em; /* 18px */
        line-height: 1.25em;
        padding: 10px 3px;
        text-decoration: none;
        font-weight: bold;

        &:hover {
          color: $blue-dark;
          text-decoration: none;
        }

        &.active, &.active-trail {
          color: $orange;
          text-decoration: none;
        }
      }

      /* Third Level */

      &.expanded ul.menu {
        padding-bottom: 15px;

        li {
          background-image: none;
          margin-left: 0;
          padding-left: 0;
          list-style: none;
          width: 100%;

          a {
            color: $blue;
            font-weight: normal;
            font-size: 1em; /* 16px */
            text-decoration: none;
            padding: 5px 0px 0px 3px;

            &:hover {
              color: $blue-dark;
              text-decoration: none;
            }

            &.active {
              color: $orange;
              text-decoration: none;
            }

            &::before {
              content: '\2022';
              padding-right: 7px;
              font-size: 0.9em;
            }
          }
        }
      }
    }
  }
}
