/**
 * Homepage Search
 */

#home-search {
  box-sizing: border-box;
  height: 355px;
  background: url(../gfx/search-background.jpg) top center no-repeat #e5812f;
  padding-top: 69px;

  .home-search-cont {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 43px 50px 60px;
    max-width: 940px;
    background-color: rgba(185, 78, 1, 0.6);
    @include border-radius(6px);
    text-align: center;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -ms-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;

    &:hover {
      background-color: rgba(185, 78, 1, 0.75);
    }

    h2 {
      font-size: 2.250em; /* 36px */
      color: #fff;
      letter-spacing: 0.04em;
      padding-bottom: 0.5em;
    }

    #views-exposed-form-product-catalog-page {
      width: 100%;
      height: 54px;
      background-color: #fff;
      @include border-radius(5px);

      .views-widget-filter-search_api_views_fulltext {
        display: inline-block;
        margin: 0;
        padding: 0;
        width: calc(100% - 200px);
        float: left;

        .form-item-search-api-views-fulltext input.form-text {
          width: 100%;
          height: 54px;
          padding: 0 10px 0 18px;
          margin: 0;
          background-color: transparent;
          color: $gray;
          font-family: $font-family;
          font-size: 1.625em; /* 26px */
          font-style: italic;
          border: none;
          outline: none;
          box-sizing: border-box;
        }
      }

      .views-submit-button {
        background-color: transparent;
        display: inline-block;
        float: right;
        text-align: right;
        padding: 2px;
        width: 200px;
        height: 54px;
        box-sizing: border-box;

        input.form-submit {
          height: 46px;
          padding: 0 47px;
          margin-top: 0;
          font-family: $font-family;
          font-size: 1.750em; /* 26px */
          font-weight: bold;
          text-decoration: none;
          color: #fff;
          background: $blue-dark;
          background: -moz-linear-gradient(top, #2d3b83 50%, $blue-dark 51%);
          background: -webkit-linear-gradient(top, #2d3b83 50%,$blue-dark 51%);
          background: linear-gradient(to bottom, #2d3b83 50%,$blue-dark 51%);
          border: 2px solid $blue-dark;
          @include border-radius(5px);
          -moz-box-sizing: content-box;
          -webkit-box-sizing: content-box;
          box-sizing: content-box;

          &:hover, &:focus {
            background: $blue;
            background: -moz-linear-gradient(top, $blue-dark 50%, #2d3b83 51%);
            background: -webkit-linear-gradient(top, $blue-dark 50%,#2d3b83 51%);
            background: linear-gradient(to bottom, $blue-dark 50%,#2d3b83 51%);
          }
        }
      }
    }
  }
}

/**
 * Bottom Content Area
 */

#homepage-bottom-content {
  padding-top: 21px;
}

#bottom-left {
  float: left;
  width: 66%;
  padding-bottom: 30px;
}

#bottom-right{
  float: right;
  width: 31%;
  padding-bottom: 30px;

  .bottom-right-title {
    width: 100%;
    border-bottom: 1px solid $gray-border;
    margin-bottom: -1px;
    padding-bottom: 2px;

    h3 {
      font-size: 1.375rem; /* 22px */
      font-weight: normal;

      &::before {
        font-family: FontAwesome;
        content: "\f099";
        color: #ccc;
        font-size: 1.5em;
        font-weight: normal;
        padding: 0 10px 0 6px;
        position: relative;
        top: 5px;
      }
    }
  }
}

/**
 * Footer Logos
 */

.view-footer-logos {
  padding-bottom: 28px;

  .view-content {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    justify-content: space-around;

    .views-row {
      width: 245px;
      height: 85px;
      line-height:85px;
      text-align: center;
      padding-bottom: 20px;
    }
  }
}
