//== Custom SASS

//== Colors

$blue:                  #435092 !default;
$blue-dark:             #0b2777 !default;
$orange:                #fe7112 !default;
$orange-light:          #fd9947 !default;
$gray:                  #666666 !default;
$gray-light:            #999999 !default;
$gray-border:           #cccccc !default;

$body-bg:               #ededed !default;

$text-color:            $gray !default;
$link-color:            $blue !default;
$link-hover-color:      $blue-dark !default;


//== Typography

$font-family:           'Lato', Arial, sans-serif !default;
$font-family-heading:   'Lato', Arial, sans-serif !default;

$font-size-base:          16px !default;

$font-size-h1:            2.250rem !default; // ~36px
$font-size-h2:            1.750rem !default; // ~28px
$font-size-h3:            1.375rem !default; // ~22px
$font-size-h4:            1.125rem !default; // ~18px
$font-size-h5:            $font-size-base !default;
$font-size-h6:            0.875rem !default; // ~14px

$line-height-base:        1.65em !default;


//== Mixins

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}
